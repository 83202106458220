import { Box } from '@mui/material';
import styled from 'styled-components';

export const Climat = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(16)}; 
  padding-bottom: ${theme.spacing(16)}; 

  .container {
    position: relative;
  }

  .title {
    position: relative;
    z-index: 15;
    margin: auto;
    padding-bottom: ${theme.spacing(4)}; 
    width: fit-content;
    font-weight: 500;
    line-height: 1.5;
  }

  .show-more {
    width: 54px;
    height: 54px;
    position: absolute;
    bottom:-30px;
    right:20px;
    background-color: #6b8393;
    border-radius: 50%;
    cursor: pointer;
  }

  .box {
    position: absolute;
    width: 30px;
    height: 200px;
    background-color: ${theme.palette.secondary.main};
    bottom: 0;
  }
  .right-box {
    right: 0;
  }
  .left-box {
    left: 0;
  }
  .year {
    z-index: 15;
    position: absolute;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }

  .level {
    z-index: 15;
    position: relative;
  }

  .picto-climat {
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
    ${theme.breakpoints.down(1270)} {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
`);
