import { Box } from '@mui/material';
// import React, { useEffect, useState } from 'react';




let idInterval, element;
const pointer = {x: -100, y:-100};
const lastPosition = { x: -100, y: -100 };

function enterFrame() {
  if(element) {
    const diffX = pointer.x - lastPosition.x;
    const diffY = pointer.y - lastPosition.y;
    // const dist = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
    const dist = Math.abs(diffX) + Math.abs(diffY); // distance de manhattan pour les perfs
    const factor = 0.05;

    if(dist > 2) {
      lastPosition.x = lastPosition.x + diffX * factor;
      lastPosition.y = lastPosition.y + diffY * factor;
    } else {
      lastPosition.x = pointer.x;
      lastPosition.y = pointer.y;
    }
    element.style.left = `${lastPosition.x}px`;
    element.style.top = `${lastPosition.y}px`;
  }
}

function handleMouseMove(event) {
  pointer.x = event.clientX - 30;
  pointer.y = event.clientY - 30;
}

function startCursor(node) {
  clearInterval(idInterval);
  window.removeEventListener('mousemove', handleMouseMove);

  element = node;
  idInterval = setInterval(enterFrame, 10);
  window.addEventListener('mousemove', handleMouseMove);
};

function Cursor(props) {

  // const [cusrorPosition, setCursorPosition] = useState({
  //   xp: 0,
  //   yp: 0
  // });

  // const [bubblePosition, setBubblePosition] = useState({
  //   xp: 0,
  //   yp: 0
  // });

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     const p = { xp: event.clientX - 30, yp: event.clientY - 30 };
  //     setCursorPosition(p);
  //   };
  //   window.addEventListener('mousemove', handleMouseMove );
  //   return () => {
  //     window.removeEventListener(
  //       'mousemove',
  //       handleMouseMove
  //     );
  //   };
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setBubblePosition({ ...cusrorPosition });
  //   }, 200);
  // }, [cusrorPosition]);


  return (
    <Box
      ref={node => startCursor(node)}
      sx={{
        position: 'fixed',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        // left: `${bubblePosition.xp}px`,
        // top: `${bubblePosition.yp}px`,
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        // backgroundColor: 'warning.main',
        backdropFilter: 'blur(6px)',
        pointerEvents: 'none',
      }}
      >
    </Box>
  );
}

export default Cursor;