import { Box, Container, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import StyledDialog from '../../components/StyledDialog';
import StyledButton from '../../components/StyledButton';
import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Regards } from './style';
import Year from '../../components/Year';

function B03(props) {
  const { baseUrl } = props;

  const base = data.blocks.b03regards;
  const imgBase = `${baseUrl}/blocks/`;

  const [active, setActive] = useState([false, false, false]);

  const handleClick = (position) => {
    const updatedCheckedState = active.map((item, index) =>
      index === position ? !item : item
    );
    setActive(updatedCheckedState);
  };

  return (
    <Regards className='intro'
      sx={{ bgcolor: "secondary.main" }}
    >
      <Container maxWidth='lg' className='container'>
        <Typography variant='h1' color='primary' className='title'>{parse(base.title)}</Typography>
        <Year color="primary" />    
        <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 0 }} justifyContent='space-between'>
          {
            base.directors.map((director, idx) => {
              return (
                <Box key={idx}
                  sx={{
                    position: 'relative',
                    textAlign: 'center',
                    width: {xs: '100%', md:'45%'},
                    height: 700,
                    backgroundImage: `url(${imgBase}${director.bg})`,
                    backgroundSize: '100% 66%',
                    backgroundPosition: `${idx === 0 ? 'bottom' : 'top'}`,
                    backgroundRepeat: 'no-repeat',
                    zIndex: 10,
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      position: 'absolute',
                      zIndex: 10,
                      bottom: `${idx === 0 ? '33%' : '0'}`,
                      left: 50,
                      margin: 'auto',
                      display: 'block',
                      objectFit: 'contain',
                      width: 'auto',
                      height: 500,
                    }}
                    src={`${imgBase}${director.image}`}
                    className='director-img'
                  />
                  <Box className='blur'
                    sx={{
                      position: 'absolute',
                      zIndex: 5,
                      bottom: `${idx === 0 ? '33%' : '0'}`,
                      left: 0,
                      height: '66%',
                      width: '100%',
                      backdropFilter: 'blur(30px)',
                    }}
                  >
                  </Box>
                  <Typography variant='h3' color={idx === 0 ? 'primary' : 'secondary'} className='btn-text level'
                    sx={{
                      position: 'absolute',
                      zIndex: 5,
                      top: { xs: idx === 0 ? '200px' : '400px', md: idx === 0 ? '10%' : '45%'},
                      left: {xs : 0, md: idx === 0 ? '50%' : '55%'},
                      width: {xs: 'auto', md: idx === 0 ? '45%' : '45%'},
                      textAlign: 'left',
                    }}
                  >{parse(director.quote)}</Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 10,
                      bottom: { xs: idx === 0 ? '40%' : '15%', md: idx === 0 ? '40%' : '10%'},
                      left: {xs: '80px', md: idx === 0 ? '50%' : '60%'},
                      textAlign: 'left',
                    }}
                  >
                    <Typography className='level' variant='body1' color={idx === 0 ? 'secondary' : 'primary'}      
                      >{parse(director.name)}</Typography>
                    <Typography className='level' variant='body2' sx= {{ marginBottom: 1 }} color={idx === 0 ? 'secondary' : 'primary'}        
                      >{parse(director.function)}</Typography>
                    <StyledButton icon='plus' iconMove='plus' color={idx === 0 ? 'secondary' : 'primary'} onClick={() => handleClick(idx)}></StyledButton>
                  </Box>
                  <StyledDialog bgImg={`url(${imgBase}${director.bg})`} open={active[idx]} handleDialog={() => handleClick(idx)} data={base.directors[idx]} />
                </Box>
              )
            })
          }
        </Stack>
      </Container>
      <Box className='box right-box'
        sx={{
          display: { xs: 'none', sm: 'block' },
          backgroundImage: `url(${imgBase}${base.bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></Box>
      <Box className='box left-box'
        sx={{
          display: { xs: 'none', sm: 'block' },
          backgroundImage: `url(${imgBase}${base.bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></Box>
    </Regards>
  );
}

export default B03;