export const data = {
  blocks: {
    b00intro: {
      bg: 'b00-bg.jpg',
      title: 'RAPPORT ANNUEL',
      imageTitle: 'b00-title.png',
      imageTitleMobile: 'b00-title-mobile.png',
      logo: 'rafp-logo.svg',
      btnComplete: 'TÉLÉCHARGEZ<br/>LE RAPPORT ANNUEL',
      btnDurability: 'TÉLÉCHARGEZ<br/>LE RAPPORT DURABILITÉ',
    },
    b01def: {
      title: 'LE RÉGIME DE RETRAITE ADDITIONNELLE DE LA FONCTION PUBLIQUE, FONDS DE PENSION DURABLE À LA GESTION RESPONSABLE',
    },
    b02presentation: {
      bg: 'b02-bg.jpg',
      title: 'Chaque année, l’ERAFP propose dans son rapport annuel de revenir sur le pilotage du Régime, d’expliciter la stratégie et la performance de l’Établissement ainsi que de fournir une analyse financière et extra financière de ses portefeuilles. Pour répondre aux nouvelles exigences règlementaires en matière de transparence et de reporting, le rapport annuel de l’ERAFP est depuis 2022 enrichi d’un rapport dédié à sa stratégie ESG. Pensées en complémentarité, ces deux publications offrent ainsi une vision complète des actions de l’Établissement.',
      date: '2022',
    },
    b03regards: {
      title: 'REGARDS <b>CROISÉS</b>',
      date: '2022',
      bg: 'b05-texture.jpg',
      directors: [
        {
          name: 'DOMINIQUE LAMIOT,',
          function: 'PRÉSIDENT DE L’ERAFP',
          desc: '<b>DOMINIQUE LAMIOT,</b> PRÉSIDENT DE L’ERAFP',
          quote: '« Une gouvernance à l’équilibre remarquable »',
          text: '« La lente sortie des années Covid ne s’est pas traduite par une période de « retour à la normale » mais par l’apparition de nouveaux déséquilibres. Pour autant, la robustesse de l’ERAFP, aussi singulier que novateur dans le paysage des retraites en France, a été de nouveau mise en lumière. »',
          image: 'b03-dominiquelamiot.png',
          bg: 'ice_wallpaper.jpg',
        },
        {
          name: 'LAURENT GALZY,',
          function: 'DIRECTEUR DE L’ERAFP',
          desc: '<b>LAURENT GALZY,</b> DIRECTEUR DE L’ERAFP',
          quote: '« Un engagement toujours plus exigeant »',
          text: '« L’ERAFP a su résister aux tensions et aux chocs qui ont bousculé les marchés obligataires et boursiers cette année, résultat d’une stratégie d’allocation d’actifs menée avec rigueur, prudence et dans un engagement ISR toujours plus exigeant. »',
          image: 'b03-laurentgalzy-v2.png',
          bg: 'ice_wallpaper.jpg',
        },
      ],
    },
    b04kpis: {
      bg: 'b00-bg.jpg',
      bgText: 'b02-bg.jpg',
      title: 'LES <b>CHIFFRES CLÉS</b>',
      kpis: [
        {
          number: '38,2 Md€',
          desc: "d'actif net en valeur économique au 31 décembre",
        },
        {
          number: '3,7%',
          desc: 'de taux de rendement interne des placements annualisé depuis la création du RAFP',
        },
        {
          number: '428 M€',
          desc: 'de prestations versées',
        },
        {
          number: '2,06 Md€',
          desc: 'de cotisations encaissées',
        },
        {
          number: '4,5 M',
          desc: 'de bénéficiaires cotisants',
        },
        {
          number: '44 000',
          desc: 'employeurs cotisants',
        },
        {
          number: '121,1 %',
          desc: 'de taux de couverture économique après revalorisation',
        }
      ],
      date: '2022',
    },
    b05keydate: {
      title: 'FAITS <b>MARQUANTS</b>',
      keydates: [
        {
          bg: 'b05-ice.jpg',
          date: '7 juin',
          desc: 'L’ERAFP publie son premier rapport de durabilité',
        },
        {
          bg: 'b05-sand.jpg',
          date: '27 septembre',
          desc: 'L’ERAFP lance un appel d’offres pour sélectionner des gérants de portefeuille d’actions cotées de la zone euro, Europe et Japon ISR',
        },
        {
          bg: 'b05-water.jpg',
          date: '1<sup>er</sup> décembre',
          desc: 'L’ERAFP remporte le prix de la meilleure stratégie ESG aux IPE Awards',
        },
        {
          bg: 'b05-stone.jpg',
          date: '14 décembre',
          desc: 'L’ERAFP signe la déclaration du secteur financier sur la biodiversité',
        },
        {
          bg: 'b05-rock.jpg',
          date: '15 décembre',
          desc: 'Le conseil d’administration de l’ERAFP revalorise de 5,7% la valeur de service du point pour 2023.<br><br>L’ERAFP renforce ses engagements dans l’objectif de réduction des émissions de gaz à effet de serre de son portefeuille immobilier',
        },
      ],
      date: '2022',
    },
    b06mission: {
      bg: 'b02-bg.jpg',
      bgModal: 'ice_wallpaper.jpg',
      title: 'LES MISSIONS DU <b>RAFP</b>',
      desc: 'Opérationnelle depuis 2005, la retraite additionnelle de la fonction publique constitue un régime original',
      btn: [
        { text: 'Apporter un supplément de retraite aux fonctionnaires' },
        { text: 'Promouvoir les valeurs du service public via l’investissement socialement responsable' },
        { text: 'Concilier rendement financier et recherche d’impact' },
      ],
      modal: [
        {
          title: 'Apporter un supplément de retraite aux fonctionnaires',
          text: 'Grâce au Régime, près de 4,5 millions d’agents cotisants bénéficient d’un supplément de retraite. Leurs cotisations, assises principalement sur les primes, sont complétées par celles des quelque<br> 44 000 employeurs publics. Au total, elles ont représenté un montant de 2,06 Md€ encaissés en 2022.'
        },
        {
          title: 'Promouvoir les valeurs du service public via l’investissement socialement responsable',
          text: 'L’ERAFP est un établissement public qui gère un régime de retraite au bénéfice des fonctionnaires. Cette particularité en fait un acteur voué à l’intérêt général et portant une forte culture des enjeux sociaux.<br><br>Il a ainsi élaboré, dès sa création, une démarche ambitieuse pour mettre en œuvre une politique d’investissement socialement responsable (ISR), fondée sur les valeurs du service public. Elle tient compte de critères sociaux, environnementaux et de bonne gouvernance pour l’ensemble des placements du Régime.'
        },
        {
          title: 'Concilier rendement financier et recherche d’impact',
          text: 'Seul fonds de pension public français, l’ERAFP entend démontrer qu’un investisseur peut jouer un rôle au service de l’intérêt général, sans pour autant renoncer au rendement financier de ses placements.<br><br>Trois domaines d’intervention spécifiques ont ainsi été ciblés par l’Établissement : la contribution à la transition vers une économie décarbonée, le soutien à l’activité économique et à l’emploi et le financement du logement accessible au bénéfice des agents publics.'
        },
      ],
      date: '2022',
    },
    b07map: {
      bg: 'b09-ice.jpg',
      map: 'b07-map.svg',
      circle: 'b07-circle.png',
      date: '2022',
      title: 'UN ENGAGEMENT CONTINU<br> <b>AUPRÈS DES EMPLOYEURS PUBLICS</b>',
      text1: 'Durant les premières années de son existence, le Régime a essentiellement accompagné les employeurs dans la mise en œuvre pratique de leurs responsabilités. Il cherche aujourd’hui à mieux connaître leurs besoins et à les sensibiliser également à sa spécificité de régime par capitalisation et d’investisseur de long terme socialement responsable.',
      text2: "L’ERAFP organise ainsi, à une fréquence trimestrielle, des « Rencontres des employeurs publics sur la Retraite additionnelle » en région. Véritables moments d’échange et de partage d’expérience, ces « Rencontres » sont l’occasion d’un contact direct avec les principaux relais du Régime, afin que les équipes de l’ERAFP et de son gestionnaire administratif prennent mieux en compte leurs besoins en termes d’information et d’outils.",
      text3: "Après avoir connu une phase d’interruption liée au contexte sanitaire, l’Établissement a repris l’organisation de ces rendez-vous au second semestre 2021. En 2022, ce sont près de 150 employeurs des régions Bourgogne - Franche-Comté, Occitanie et Auvergne - Rhône-Alpes qui ont ainsi pu y assister.",
    },
    b08ISR: {
      date: '2022',
      bg: 'b00-bg.jpg',
      logo: 'b08-isr.png',
      title: 'UN ENGAGEMENT ISR <b>PUISSANT</b>',
      desc: '<b>Depuis 2006, l’ERAFP met en œuvre une politique d’investissement socialement responsable (ISR) qui est au cœur de la stratégie du Régime</b>, ce qui en fait un précurseur dans le domaine de l’ISR. La prise en compte du développement durable dans sa gestion financière a ainsi conduit l’ERAFP à adopter, dès sa création, une démarche d’investissement socialement responsable formalisée par une Charte qui lui est propre.',
    },
    b09value: {
      title: 'LES <b>5 VALEURS</b> DE LA CHARTE ISR DE L’ERAFP ET SES <b>18 CRITÈRES</b>',
      date: '2022',
      images: [
        { img: 'b09-ice.jpg', title: 'État de droit et droits de l\'homme' },
        { img: 'b09-sand.jpg', title: 'Progrès social' },
        { img: 'b09-water.jpg', title: 'Démocratie sociale' },
        { img: 'b09-stone.jpg', title: 'Environnement' },
        { img: 'b09-rock.jpg', title: 'Bonne gouvernance et transparence' },
      ],
      modal: [
        {
          bg: 'ice_wallpaper.jpg',
          title: 'État de droit et droits de l\'homme',
          list: [
            { text: 'Non-discrimination et promotion de l’égalité des chances' },
            { text: 'Liberté d’opinion et d’expression et autres droits fondamentaux' },
            { text: 'Gestion responsable de la chaîne d’approvisionnement' },
          ],
        },
        {
          bg: 'sand_wallpaper.jpg',
          title: 'Progrès social',
          list: [
            { text: 'Gestion responsable des carrières et anticipation en matière d’emploi' },
            { text: 'Partage équitable de la valeur ajoutée' },
            { text: 'Amélioration des conditions de travail' },
            { text: 'Impact et valeur ajoutée sociale du produit ou service' },
          ],
        },
        {
          bg: 'water_wallpaper.jpg',
          title: 'Démocratie sociale',
          list: [
            { text: 'Respect du droit syndical et promotion du dialogue social' },
            { text: 'Amélioration des conditions de santé-sécurité' },
          ],
        },
        {
          bg: 'stone_wallpaper.jpg',
          title: 'Environnement',
          list: [
            { text: 'Stratégie en matière d’environnement' },
            { text: 'Impact environnemental du produit ou service' },
            { text: 'Maîtrise des impacts environnementaux' },
            { text: 'Maîtrise des risques associés au changement climatique et contribution à la transition énergétique' },
          ],
        },
        {
          bg: 'rock_wallpaper.jpg',
          title: 'Bonne gouvernance et transparence',
          list: [
            { text: 'Protection et respect des droits du client/consommateur' },
            { text: 'Lutte contre la corruption et le blanchiment d’argent' },
            { text: 'Pratiques responsables en matière de lobbying' },
            { text: 'Transparence et responsabilité en matière fiscale' },
          ],
        },
      ]
    },
    b10card: {
      date: '2022',
      title: 'LES VALEURS ISR PORTÉES PAR LA CHARTE DE L’ERAFP CONSTITUENT <b>AUTANT DE RÉPONSES AUX DÉFIS AUXQUELS NOUS DEVONS COLLECTIVEMENT FAIRE FACE.</b>',
      defi: [
        {
          title: 'Défis en termes d’environnement et de changement climatique',
          text: "Conscient de l’urgence de la situation climatique, l’ERAFP, dans son rôle d’investisseur, cherche à inciter les entreprises à prendre en compte l’impact environnemental de leurs produits ou services, à maîtriser les risques associés au changement climatique, à adopter une stratégie d’alignement avec un scénario de réchauffement de 1,5° C et à contribuer à la transition énergétique.",
        },
        {
          title: 'Défis en termes de gouvernance',
          text: "L’ERAFP cherche à promouvoir les entreprises ayant une gouvernance qui intègre l’équilibre des pouvoirs, l’efficacité des mécanismes de contrôle, une politique de rémunération responsable et qui respecte la parité femmes-hommes. La qualité de la gouvernance permet de répondre aux défis en termes de lutte contre la corruption et de blanchiment d’argent, de protection et de respect des droits du client, de transparence et de responsabilité en matière fiscale."
        },
        {
          title: 'Défis en termes sociaux',
          text: "En tant qu’établissement public français, l’ERAFP entend préserver les acquis sociaux au travers de la promotion du dialogue social et du respect du droit syndical. L’ERAFP souhaite également promouvoir l’état de droit et les droits de l’Homme pour ses investissements souverains, mais aussi pour ses investissements privés et enfin, attend des entreprises que leur stratégie de transition intègre les principes d’une transition juste."
        }
      ],
    },
    b11climat: {
      bg: 'b00-bg.jpg',
      title: 'L’ERAFP <b>ET LE CHANGEMENT CLIMATIQUE</b>',
      subtitle: 'Conformément aux objectifs qu’il s’est fixés dans le cadre de sa feuille de route climat, l’ERAFP a publié ses résultats dans la partie 5 de son rapport de durabilité 2022.',
      text1: 'RAPPEL DES OBJECTIFS FIXÉS PAR L’ERAFP <b>DANS LE CADRE DE SA FEUILLE DE ROUTE CLIMAT</b>',
      text2: 'DANS LE CADRE DE SA FEUILLE DE ROUTE CLIMAT',
      date: '2022',
      bandeau: [
        { text: 'Réduction des émissions de gaz à effet de serre' },
        { text: 'Engagement' },
        { text: 'Financement de la transition' },
        { text: 'Alignement de température' },
      ],
      modal: [
        {
          title: 'Réduction des émissions de gaz à effet de serre',
          type: 'ul',
          start: 1,
          list: [
            { text: 'Réduire de 25 % l’intensité carbone du portefeuille d’entreprises cotées AOA entre 2019 et 2024 (scopes 1 et 2).' },
            { text: 'Alignement du portefeuille immobilier AOA avec le point de passage 2025 du scénario<br> CRREM 1,5 °C (scopes 1 et 2 et une partie des émissions du scope 3).' },
            { text: 'Réduire de 15 % l’intensité surfacique du portefeuille immobilier AOA entre 2019 et 2024.' },
          ],
        },
        {
          title: 'Engagement',
          type: 'ul',
          start: 4,
          list: [
            { text: 'Construire un dialogue actionnarial avec 30 entreprises parmi les plus émissives du portefeuille en faveur d’une transition énergétique conformément aux objectifs de l’Accord de Paris.' },
          ],
        },
        {
          title: 'Financement de la transition',
          type: 'ul',
          start: 5,
          list: [
            { text: 'Augmenter les montants investis dans des actifs contribuant à la transition énergétique et à la décarbonation de l’économie.' }
          ],
        },
        {
          title: 'Alignement de température',
          type: 'ul',
          start: 6,
          list: [{
            text: 'Atteindre une proportion de 50 % d’entreprises (en pourcentage de l’empreinte carbone) s’étant fixé un objectif d’alignement de température inférieur ou égal à 1,5° C validé par la SBTi.'
          }]
        },
      ],
    },
    b12steps: {
      bg: 'b12-bg.png',
      title: 'LES PAS EN AVANT<br/><b>RÉALISÉS EN 2022</b>',
      texts: [
        'En matière d’ISR, l’année 2022 a été pour l’ERAFP marquée par sa mise en œuvre du nouveau cadre réglementaire applicable aux investisseurs français en matière de publication d’informations extra-financières, qui s’est matérialisée, en juin, par la publication de son premier rapport de durabilité.',
        'L’ERAFP a également lancé, en cours d’année, un appel d’offres pour sélectionner un ou plusieurs cabinets de conseil spécialisés en stratégie climat et biodiversité. Au-delà de l’aspect strictement réglementaire, le souci de l’ERAFP d’étendre l’analyse de ses portefeuilles à l’exposition aux enjeux liés à la biodiversité s’inscrit dans une démarche plus générale de renforcement de son engagement sur cet enjeu, il a ainsi signé, aux côtés d’autres investisseurs, une déclaration du secteur financier sur la biodiversité.',
        'Enfin, l’ERAFP a aussi progressé, en 2022, sur un autre sujet d’engagement : celui de la prise en compte des enjeux climatiques. Alors qu’il s’était fixé en 2021, dans le cadre de sa feuille de route climat, l’objectif d’aligner son portefeuille immobilier non résidentiel avec un scénario d’augmentation de la température de 1,5° C, il a pu, grâce à une meilleure disponibilité des données, étendre le périmètre retenu aux actifs résidentiels.',
      ],
      date: '2022',
    },
    b13engaged: {
      title: 'UN INVESTISSEUR <b>ENGAGÉ</b>',
      texts: [
        {
          title: 'Auprès des entreprises',
          content: "Depuis 10 ans, l’approche de l’ERAFP s’est enrichie en matière d’engagement actionnarial via sa participation directe à des engagements collectifs avec d’autres investisseurs ou encore en exerçant des droits de vote lors des assemblées générales d’actionnaires. Sur les 1 022 émetteurs du portefeuille d’entreprises cotées de l’ERAFP, 429 ont fait l’objet d’au moins une action d’engagement, soit 42 %.",
        },
        {
          title: 'En pointe sur les standards européens',
          content: "En 2022, l’ERAFP, conformément à la mise en place progressive des réglementations européennes relatives à la finance durable, a publié, pour la première fois, la part des revenus issus des entreprises cotées de son portefeuille éligibles à la taxonomie européenne.",
        },
      ],
      bgText: 'sand_wallpaper.jpg',
      kpi1: '80%',
      kpi1desc: "de l’encours des entreprises cotées détenu par l’ERAFP a fait l’objet d’au moins une action d’engagement en 2022",
      kpi2: '41%',
      kpi2desc: 'des revenus des entreprises cotées détenues par l’ERAFP sont éligibles à la taxonomie européenne',
      date: '2022',
    },
    b14footer: {
      bg: 'b00-bg.jpg',
      tel: '+(33) 1 58 50 96 36',
      address: '12, rue Portalis<br> CS 40 007<br> 75381 Paris Cedex 08',
      luciole: 'Design <b>LUCIOLE 2023</b>',
      mail: 'communication@erafp.fr',
      twitter: '@_ERAFP_',
      linkedin: 'ERAFP',
      download: 'TÉLÉCHARGEZ<br> LA VERSION COMPLÈTE DU <br>RAPPORT ANNUEL DE RAFP',
      logo: 'logo-footer.svg',
      twitterLogo: 'twitter.svg',
      linkedinLogo: 'linkedin.svg',
    }
  },
  components: {
    menu: {
      chapters: [
        {
          title: 'REGARDS CROISÉS',
          anchor: 'crossedlooks',
        },
        {
          title: 'CHIFFRES CLÉS',
          anchor: 'kpis',
        },
        {
          title: 'FAITS MARQUANTS',
          anchor: 'facts',
        },
        {
          title: 'LES MISSIONS DU RAFP',
          anchor: 'missions',
        },
        {
          title: 'UN ENGAGEMENT CONTINU',
          anchor: 'public',
        },
        {
          title: 'UN ENGAGEMENT ISR PUISSANT',
          anchor: 'engaged',
        },
        {
          title: 'LES 5 VALEURS DE LA CHARTE ISR',
          anchor: 'values',
        },
        {
          title: 'L’ERAFP ET LE CHANGEMENT CLIMATIQUE',
          anchor: 'climat',
        },
        {
          title: 'LES PAS EN AVANT RÉALISÉS EN 2022',
          anchor: 'step',
        },
        {
          title: 'UN INVESTISSEUR ENGAGÉ',
          anchor: 'invest',
        },
      ],
    },
  },
  popups: {
  },
  pdf: {
    rapport: 'https://www.rafp.fr/sites/default/files/2023-06/RAFP-Rapport-annuel-2022.pdf',
    durabilite: 'https://www.rafp.fr/sites/default/files/2023-06/RAFP-Rapport-durabilite-2022.pdf',
  },
};
