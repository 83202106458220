import { Box, Container, LinearProgress, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Map } from './style';
import { useState } from 'react';
import Year from '../../components/Year';

function B07(props) {
  const [active, setActive] = useState([true, true, true]);

  const handleClick = (position) => {
    const updatedCheckedState = active.map((item, index) =>
      index === position ? false : item
    );
    setActive(updatedCheckedState);
  };

  const { baseUrl } = props;

  const base = data.blocks.b07map;
  const imgBase = `${baseUrl}/blocks`;
  const cityMap = [
    `${imgBase}/texture_wallpaper.jpg`,
    `${imgBase}/b07_Dijon.jpg`,
    `${imgBase}/b07_Lyon.jpg`,
    `${imgBase}/b07_Montpellier.jpg`,
  ];

  const [city, setCity] = useState(cityMap[0]);

  return (
    <Map className='intro'
      sx={{ bgcolor: "secondary.main" }}
    >
      <Container maxWidth='lg' className='container' sx={{ paddingX: { xs: 2, sm: 6, md: 2 } }}>
        <Typography variant='h1' color='primary' className='title'>{parse(base.title)}</Typography>
        <Year color="primary" />

        <Box sx={{
          display: "flex",
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          gap: 4,
          paddingLeft: { xs: 0, md: 8 },
          paddingRight: { xs: 0, md: 8 },
        }}>
          <Box sx={{ position: 'relative', zIndex: 15 }}>
            <Typography variant='body2' color='grey.main'>{parse(base.text1)}</Typography>
            <Typography mt={3} variant='body2' color='grey.main'>{parse(base.text2)}</Typography>
            <Typography mt={3} variant='body2' color='grey.main'>{parse(base.text3)}</Typography>
          </Box>
          <Box sx={{
            position: 'relative',
            width: 'fit-content',
            display: { xs: 'none', md: 'block' },
          }}>
            <img src={`${imgBase}/${base.map}`} alt="" className='map-img' />
            <Box sx={{
              position: 'absolute',
              top: 35,
              right: -80,
              width: 380,
              height: 380,
              backgroundColor: 'white.main',
              borderRadius: '50%',
              zIndex: 1,
              backgroundImage: `url(${city})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
            </Box>
            <Box className='city' sx={{ top: 150, right: 200, }}
              onClick={() => {
                setCity(cityMap[1]);
                handleClick(0);
              }}>
              <Typography variant='body1' color='secondary' >DIJON</Typography>
              <Box className='circle'></Box>
              {active[0] ? (
                <LinearProgress sx={{ position: 'absolute', bottom: -5, width: '100%', height: '2px' }} />
              )
                : (
                  <LinearProgress color="white" sx={{ position: 'absolute', bottom: -5, width: '100%', height: '1.5px' }} variant="determinate" value={100} />
                )
              }
            </Box>

            <Box className='city' sx={{ top: 230, right: 180, }} onClick={() => {
              setCity(cityMap[2]);
              handleClick(1);
            }}>
              <Typography variant='body1' color='secondary' >LYON</Typography>
              <Box className='circle'></Box>
              {active[1] ? (
                <LinearProgress sx={{ position: 'absolute', bottom: -5, width: '100%', height: '2px' }} />
              )
                : (
                  <LinearProgress color="white" sx={{ position: 'absolute', bottom: -5, width: '100%', height: '1.5px' }} variant="determinate" value={100} />
                )
              }
            </Box>

            <Box className='city' sx={{ top: 340, right: 225, }} onClick={() => {
              setCity(cityMap[3]);
              handleClick(2);
            }}>
              <Typography variant='body1' color='secondary' >MONTPELLIER</Typography>
              <Box className='circle'></Box>
              {active[2] ? (
                <LinearProgress sx={{ position: 'absolute', bottom: -5, width: '100%', height: '2px' }} />
              )
                : (
                  <LinearProgress color="white" sx={{ position: 'absolute', bottom: -5, width: '100%', height: '2px' }} variant="determinate" value={100} />
                )
              }
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{
        display: { xs: 'none', sm: 'block' },
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} className='box right-box'></Box>
      <Box sx={{
        display: { xs: 'none', sm: 'block' },
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} className='box left-box'></Box>
    </Map>
  );
}

export default B07;