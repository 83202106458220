import { Box } from '@mui/material';
import styled from 'styled-components';

export const Keydate = styled(Box)(({ theme }) => ({
  position: 'relative',

  '.container': {
    position: 'relative',
  },
  '.title': {
    position: 'relative',
    zIndex: 15,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(24),
  },
  ' .year': {
    zIndex: 15,
    position: 'absolute',
    width: 'fit-content',
    top: '50%',
  },
  '.right-year': {
    transform: 'translateY(-50%) rotate(90deg)',
    left: '100%',
  },
  '.left-year': {
    transform: 'translateY(-50%) rotate(-90deg)',
    right: '100%',
  },
  '.box': {
    position: 'absolute',
    width: 30,
    height: 200,
    backgroundColor: theme.palette.primary.main,
    bottom: 0,
  },
  '.right-box': {
    right: 0,
  },
  '.left-box': {
    left: 0,
  },

  '.wrapper': {
    position: 'relative',
    zIndex: 4,
    transform: 'translateY(0)',
    transition: 'transform 0.3s ease-in-out',
  },
  '.desktop & .ghost:hover + .wrapper, .openTile': {
    transition: 'transform 0.5s ease-in-out',
    transform: 'translateY(-50%)',
    '&.item-4' : {
      transform: 'translateY(-80%)',
    },
  },

  '.grid-desc': {
    position: 'absolute',
    zIndex: 3,
    pointerEvents: 'none',
    bottom: 15,
    padding: `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    textAlign: 'center',
    color: theme.palette.grey.main,
  },
}));