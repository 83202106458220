import { Box, Container, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';
import { Mission } from './style';
import { useState } from 'react';
import StyledDialog from '../../components/StyledDialog';
import StyledButton from '../../components/StyledButton';
import Year from '../../components/Year';

function B06(props) {
  const [active, setActive] = useState([false, false, false]);

  const handleClick = (position) => {
    const updatedCheckedState = active.map((item, index) =>
      index === position ? !item : item
    );
    setActive(updatedCheckedState);
  };

  const { baseUrl } = props;

  const base = data.blocks.b06mission;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Mission className='presentation'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}    >
      <Container maxWidth='lg' className='container'>
        <Typography variant='h1' color='secondary' className='title'>{parse(base.title)}</Typography>
        <Typography variant='h5' color='secondary' className='desc' sx={{ position: 'relative', zIndex: 15, maxWidth: 600, m: 'auto'  }}>{parse(base.desc)}</Typography>
        <Year />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: 2,
            marginTop: 5
          }}
        >
          {base.btn.map((btn, idx) => (
            <Box key={idx} sx={{ textAlign: 'center', maxWidth: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
              <StyledButton icon='plus' iconMove='plus' onClick={() => handleClick(idx)}></StyledButton>
              <Typography variant='body2' color='secondary' className='btn-text' sx={{ position: 'relative', zIndex: 15, mt: 2, maxWidth: 250}}>{parse(btn.text)}</Typography>
              <StyledDialog bgImg={`url(${imgBase}/${base.bgModal})`} open={active[idx]} handleDialog={() => handleClick(idx)} data={base.modal[idx]} />
            </Box>
          ))}
        </Box>
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Mission>
  );
}

export default B06;