import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

const primary = '#D4B993';

const Theme = {
  palette: {
    // Standard colors
    primary: {
      main: primary,
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    error: {
      main: '#d64045',
      contrastText: "#fff",
    },
    warning: {
      main: '#f2b41b',
      contrastText: "#fff",
    },
    success: {
      main: '#5fab80',
      contrastText: "#fff",
    },
    white: {
      main: '#fff',
      contrastText: '#000',
    },
    black: {
      main: '#000',
      text: '#202020',
      light: '#666666',
      contrastText: '#fff',
    },
    grey: {
      main: '#555555',
      light: '#f2f2f2',
      dark: '#b8b8b8',
      contrastText: '#000',
    },
    // Specific colors
    blue: {
      main: '#000091',
      light: '#6b8393',
      contrastText: '#fff',
    },
    green: {
      main: '#4bb58f',
      dark: '#396664',
      contrastText: '#fff',
    },
    orange: {
      main: '#ff8c32',
      light: '#ffb400',
      contrastText: '#000',
    },
    pink: {
      main: '#ff5a3c',
      light: '#ff755b',
      contrastText: '#000',
    },
    red: {
      main: '#c10046',
      contrastText: '#000',
    },
    salmon: {
      main: '#ffb887',
      contrastText: '#000',
    },
    teal: {
      main: '#4bb58f',
      contrastText: '#000',
    },
    yellow: {
      main: '#ffdf00',
      light: '#ffe73e',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: '"commissioner", arial, sans-serif',
    htmlFontSize: 16,
    h1: {
      fontFamily: '"archia", arial, sans-serif',
      fontSize: '2.9375rem',
      fontWeight: 100,
      letterSpacing: 0,
      lineHeight: 1.5,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: '"commissioner", arial, sans-serif',
      fontSize: '1.875rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.33,
    },
    h3: {
      fontFamily: '"archia", arial, sans-serif',
      fontSize: '1.625rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.30,
    },
    h4: {
      fontFamily: '"archia", arial, sans-serif',
      fontSize: '1.5rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.125,
    },
    h5: {
      fontFamily: '"commissioner", arial, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    h6: {
      fontFamily: '"archia", arial, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    body1: {
      fontFamily: '"commissioner", arial, sans-serif',
      fontSize: '1.0625rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.35,
    },
    body2: {
      fontFamily: '"commissioner", arial, sans-serif',
      fontSize: '0.9375rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.33,
    },
    button: {
      fontFamily: '"lato", arial, sans-serif',
      fontSize: '0.5625rem',
      fontWeight: 700,
      letterSpacing: '0.05rem',
      lineHeight: 1.66,
    },
    overline: {
      fontFamily: '"commissioner", arial, sans-serif',
      fontSize: '0.5625rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.66,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: 'transparent',
        }
      }
    }
  }
};

export default createTheme(Theme, frFR);
