import { Box, Container, Stack, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Presentation } from './style';
import Year from '../../components/Year';

function B13(props) {
  const { baseUrl } = props;

  const base = data.blocks.b13engaged;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Presentation className='presentation'>
      <Container maxWidth='lg' className='container'>
        <Typography variant='h1' color='primary' className='title'>{parse(base.title)}</Typography>
        <Year color="primary" />
    
        <Stack alignItems='center' sx={{ position: 'relative', margin: 'auto', width: 'fit-content' }}>
          {base.texts.map((text, idx) => (
            <Box key={idx} sx={{ marginTop: 8 }}>
              <Typography variant='body2' className='text level'
                sx={{ paddingTop: 2, maxWidth: 500, fontWeight: 700 }}
              >
                {parse(text.title)}
              </Typography>
              <Typography key={idx} variant='body2' className='text level'
                sx={{ maxWidth: 500 }}
              >
                {parse(text.content)}
              </Typography>
            </Box>
          ))}          
          <Box sx={{display: {xs: 'none', md: 'block'}}} className='kpi kpi1'>
            <Typography variant="h1" className='clip-text level' sx={{
              backgroundImage: `url(${imgBase}/${base.bgText})`,
              backgroundPosition: 'center',
              color: 'transparent',
            }}>{parse(base.kpi1)}</Typography>
            <Typography variant="body2" className='kpi-desc'>{parse(base.kpi1desc)}</Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}  className='kpi kpi2'>
            <Typography variant="h1" className='clip-text level' sx={{
              backgroundImage: `url(${imgBase}/${base.bgText})`,
              backgroundPosition: 'center',
              fontSize: '3rem',
              color: 'transparent',
            }}>{parse(base.kpi2)}</Typography>
            <Typography variant="body2" className='kpi-desc'>{parse(base.kpi2desc)}</Typography>
          </Box>
        </Stack>
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Presentation>
  );
}

export default B13;