import { Box } from '@mui/material';
import styled from 'styled-components';

export const Footer = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(16)}; 
  padding-bottom: ${theme.spacing(8)}; 

  .container {
    position: relative;
  }

  .title {
    z-index: 15;
    margin: auto;
    padding-top: ${theme.spacing(16)}; 
    padding-bottom: ${theme.spacing(4)}; 
    width: fit-content;
    font-weight: 500;
    line-height: 1.5;
  }

  .box {
    position: absolute;
    width: 30px;
    height: 200px;
    background-color: ${theme.palette.primary.main};
    top: 0;
  }
  .right-box {
    right: 0;
  }
  .left-box {
    left: 0;
  }

  .image-logo {
     z-index: 15;
    position: relative;
  }

  .level {
    z-index: 15;
    position: relative;
  }
`);
