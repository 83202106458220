import { Box } from '@mui/material';
import styled from 'styled-components';

export const Map = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.spacing(8),

  '.container': {
    position: 'relative',
  },
  '.title': {
    position: 'relative',
    zIndex: 15,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  '.city': {
    position: 'absolute',
    display: "flex",
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    cursor: 'pointer',
  },
  '.map-img': {
    position: 'relative',
    width: '100%',
    minWidth: '490px',
    height: 'auto',
    zIndex: 2,
  },
  '.circle': {
    width: 15,
    height: 15,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
  },
  ' .year': {
    position: 'absolute',
    zIndex: 15,
    width: 'fit-content',
    top: '50%',
  },
  '.right-year': {
    transform: 'translateY(-50%) rotate(90deg)',
    left: '100%',
  },
  '.left-year': {
    transform: 'translateY(-50%) rotate(-90deg)',
    right: '100%',
  },
  '.box': {
    position: 'absolute',
    width: 30,
    height: 200,
    backgroundColor: theme.palette.primary.main,
    bottom: 0,
  },
  '.right-box': {
    right: 0,
  },
  '.left-box': {
    left: 0,
  },
}));