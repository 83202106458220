import { AppContainer } from './style';
import B00intro from './blocks/B00intro';
import B01def from './blocks/B01def';
import B02presentation from './blocks/B02presentation';
import B03regards from './blocks/B03regards';
import B04kpis from './blocks/B04kpis';
import B05keydate from './blocks/B05keydate';
import B06mission from './blocks/B06mission';
import B07map from './blocks/B07map';
import B08ISR from './blocks/B08ISR';
import B09value from './blocks/B09value';
import B10card from './blocks/B10card';
import B11climat from './blocks/B11climat';
import B12steps from './blocks/B12steps';
import B13engaged from './blocks/B13engaged';
import B14footer from './blocks/B14footer';
import Cursor from './components/Cursor';
import MenuBurger from './components/MenuBurger';
import { StyledAnchor } from './components/Anchor/style';
import LoaderImage from './components/LoaderImage';
import Device from './utils/Device';


const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

function App() {
 
  return (
    <AppContainer position="relative" className={Device.mobile() ? 'mobile' : 'desktop' }>
      <MenuBurger baseUrl={BASE_URL} />
      {!Device.mobile() && <Cursor />}
      <LoaderImage baseUrl={BASE_URL} />
      <B00intro baseUrl={BASE_URL}></B00intro>
      <B01def baseUrl={BASE_URL}></B01def>
      <B02presentation baseUrl={BASE_URL}></B02presentation>
      <StyledAnchor id='crossedlooks' />
      <B03regards baseUrl={BASE_URL}></B03regards>
      <StyledAnchor id='kpis' />
      <B04kpis baseUrl={BASE_URL}></B04kpis>
      <StyledAnchor id='facts' />
      <B05keydate baseUrl={BASE_URL}></B05keydate>
      <StyledAnchor id='missions' />
      <B06mission baseUrl={BASE_URL}></B06mission>
      <StyledAnchor id='public' />
      <B07map baseUrl={BASE_URL}></B07map>
      <StyledAnchor id='engaged' />
      <B08ISR baseUrl={BASE_URL}></B08ISR>
      <StyledAnchor id='values' />
      <B09value baseUrl={BASE_URL}></B09value>
      <B10card baseUrl={BASE_URL}></B10card>
      <StyledAnchor id='climat' />
      <B11climat baseUrl={BASE_URL}></B11climat>
      <StyledAnchor id='step' />
      <B12steps baseUrl={BASE_URL}></B12steps>
      <StyledAnchor id='invest' />
      <B13engaged baseUrl={BASE_URL}></B13engaged>
      <B14footer baseUrl={BASE_URL}></B14footer>
    </AppContainer>
  );
}

export default App;
