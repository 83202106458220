import { Box, Container, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ISR } from './style';
import Year from '../../components/Year';

function B08(props) {
  const { baseUrl } = props;

  const base = data.blocks.b08ISR;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <ISR className='presentation'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Container maxWidth='lg' className='container'>
        <Box
          component="img"
          sx={{
            margin: 'auto',
            display: 'block',
            objectFit: 'cover',
            width: '100%',
            height: 'auto',
            maxWidth: { xs: 560, },
            maxHeight: { xs: 360, },
          }}
          alt=""
          src={`${imgBase}/${base.logo}`}
          className='grid-img'
          />
        <Typography variant='h1' color='secondary' className='title' textAlign="center">{parse(base.title)}</Typography>
        <Year />
        <Typography sx={{ position: 'relative', zIndex: 15, margin: 'auto', marginTop: 2, maxWidth: {xs: '100%', lg: '550px'}}} variant='body2' color='secondary' textAlign="center">{parse(base.desc)}</Typography>
      </Container>
    </ISR>
  );
}

export default B08;