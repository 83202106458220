import { Box } from '@mui/material';
import styled from 'styled-components';

export const Intro = styled(Box)(
  ({ theme }) => `
  position: relative;
  height: 100vh;

  .container {
    position: relative;
    height: 100%;
  }

  .image-title {
    z-index: 0;
    pointer-events: none;
    max-width: calc(100% - 24px);
    max-height: 100%;
    object-fit: contain;
  }

   .image-title-mobile {
    z-index: 0;
    pointer-events: none;
    width: 100%;
    height: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    position: absolute;
  }

  .image-logo {
    position: absolute;
    z-index: 15;
    width: 100px;
    height: 100px;
  }

  .title {
    z-index: 15;
  }
`);
