import { Typography } from '@mui/material';
import { YearStyle } from './style';

function Year({ color="secondary", ...props}) {

  return (
    <YearStyle>
      <Typography sx={{ display: { xs: 'none', md: 'block' }, }} variant='overline' color={color} className='year right-year'>2022</Typography>
      <Typography sx={{ display: { xs: 'none', md: 'block' }, }} variant='overline' color={color} className='year left-year'>2022</Typography>
    </YearStyle>
  );
}

export default Year;
