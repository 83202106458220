import React, { useState } from 'react';
import { Box, Link, Stack, Typography, Hidden } from '@mui/material';
import { data } from '../../utils/data';
import { Menu } from './style';
import parse from '../../utils/parse';
import StyledButton from '../../components/StyledButton';

function MenuBurger(props) {
  const [open, setOpen] = useState(false);
  const base = data.blocks.b00intro;
  const pdf = data.pdf;
  const handleOpen = () => {
    setOpen(prev => !prev);
  }
  const { baseUrl } = props;
  const chapters = data.components.menu.chapters;
  const imgBase = `${baseUrl}/blocks/`;


  return (
    <Menu>
      <Box className={`hamburger hamburger2 ${open ? 'open' : ''}`} onClick={handleOpen}>
        <span className="bar bar1"></span>
        <span className="bar bar2"></span>
        <span className="bar bar3"></span>
        <span className="bar bar4"></span>
      </Box>
      <Box className={`menu-container ${open ? 'open' : ''}`}>
        <Box sx={{
          zIndex: 1,
          position: 'absolute',
          right: 0,
          width: {xs: '100%', sm:'60%'},
          height: '100%',
          backdropFilter: 'blur(15px)',
        }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{paddingX: { xs: 2, sm: 5}, marginTop: { xs: 4, sm: 10}}}
            >
            {chapters.map((chapter, idx) => (
              <Link key={idx} href={`#${chapter.anchor}`} underline='none' onClick={() => setOpen(false)}>
              <Typography fontWeight="400" variant='h5' color='secondary'>{chapter.title}</Typography>
              </Link>
            ))}
            <Hidden lgUp>
              <StyledButton icon='arrowRight' iconMove='horizontal' component="a" href={pdf.durabilite} target="_blank">{parse(base.btnDurability)}</StyledButton>
              <StyledButton icon='arrowDown' iconMove='down' component="a" href={pdf.rapport} target="_blank">{parse(base.btnComplete)}</StyledButton>
            </Hidden>
          </Stack>
        </Box>
        <Box
          component="img"
          sx={{
            position: 'absolute',
            right: 0,
            width: { xs: '100%', md:'80%'},
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          src={`${imgBase}ice_wallpaper.jpg`}
        />
      </Box>
    </Menu>
  );
}

export default MenuBurger;