import { Box, Container, Stack, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';
import StyledButton from '../../components/StyledButton';

import { Intro } from './style';

function B00(props) {
  const { baseUrl } = props;

  const base = data.blocks.b00intro;
  const pdf = data.pdf;
  const imgBase = `${baseUrl}/blocks/`;
  const commonBase = `${baseUrl}/common/`;

  return (
    <Intro className='intro'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container maxWidth="lg" className='container'>
        <Box
          component="img"
          sx={{
            left: 15,
            top: 15,
            display: { xs: 'block', md: 'none' }
          }}
          src={`${commonBase}${base.logo}`}
          className='image-logo'
        />
        <Box
          component="img"
          sx={{
            display: { xs: 'block', md: 'none' }
          }}
          src={`${imgBase}${base.imageTitleMobile}`}
          className='image-title-mobile'
        />
        <Box sx={{
          position: 'relative',
          flexDirection: 'column',
          alignItems: "center",
          justifyContent: 'center',
          display: "flex",
          width: "100%",
          height: "100%",
        }}>
          <Box sx={{ position: 'relative', width: 'inherit', height: {xs: 'inherit', md: 'auto'} }}>
            <Box
              component="img"
              sx={{ 
                marginBottom: { xs: 4, md: 0 },
                display: { xs: 'none', md: 'block' }
              }}
              src={`${imgBase}${base.imageTitle}`}
              className='image-title'
            />
            <Typography sx={{
              position: { xs: 'absolute' },
              textAlign: { xs: 'center', md: 'left' },
              width: 'max-content',
              top: { xs: 'auto', md: '300px' },
              right: { xs: '0', md: 'auto' },
              bottom: { xs: '20px', md: 'auto' },
              margin: { xs: 'auto', md: '0' }
            }}
              variant="h2"
              color='secondary'
              className='title'>
              {parse(base.title)}
            </Typography>
            <Box
              component="img"
              sx={{
                left: 0,
                bottom: 0,
                display: { xs: 'none', md: 'block' }
              }}
              src={`${commonBase}${base.logo}`}
              className='image-logo'
            />
            <Box sx={{
              display: { xs: 'none', md: 'block' },
              position: 'absolute',
              top: { xs: '240px', sm: '90px', md: '100px' },
              right: { xs: 'auto', sm: 60, },
              left: { xs: 0, sm: 'auto' },
            }}>
              <StyledButton icon='arrowDown' iconMove='down' component="a" href={pdf.rapport} target="_blank">{parse(base.btnComplete)}</StyledButton>
            </Box>
            <Box sx={{
              display: { xs: 'none', md: 'block' },
              position: 'absolute',
              top: { xs: '240px', sm: '130px', md: '150px' },
              right: { xs: 0, sm: 60 }
            }}>
              <StyledButton icon='arrowRight' iconMove='horizontal' component="a" href={pdf.durabilite} target="_blank">{parse(base.btnDurability)}</StyledButton>
            </Box>
            {/* Mobile BTN */}
            <Box sx={{
              display: { xs: 'none',}
            }}>
              <Stack
                mt={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}>
                <Box>
                  <StyledButton icon='arrowDown' iconMove='down' component="a" href={pdf.rapport} target="_blank">{parse(base.btnComplete)}</StyledButton>
                </Box>
                <Box sx={{ paddingRight: '15px' }}>
                  <StyledButton icon='arrowRight' iconMove='horizontal' component="a" href={pdf.durabilite} target="_blank">{parse(base.btnDurability)}</StyledButton>
                </Box>
              </Stack>
            </Box>
            {/* Mobile BTN END */}
          </Box>
        </Box>
      </Container>
    </Intro>
  );
}

export default B00;