import { Box } from '@mui/material';
import styled from 'styled-components';

export const Presentation = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(16)};
  padding-bottom: ${theme.spacing(16)};

  .container {
    position: relative;
  }

  .title {
    position: relative;
    z-index: 15;
    text-align: center;
    line-height: 1.2;
  }

  .box {
    position: absolute;
    width: 30px;
    height: 200px;
    background-color: ${theme.palette.primary.main};
    bottom: 0;
  }
  .right-box {
    right: 0;
  }
  .left-box {
    left: 0;
  }
  .year {
    position: absolute;
    z-index: 15;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }
  .kpi {
    position: absolute;
    text-align: center;
  }
  .kpi1 {
    top: 20%;
    right: calc(100% + 50px);
  }
  .kpi2 {
    bottom: 10%;
    left: calc(100% + 10px);
  }
  .kpi-desc {
    z-index: 15;
    position: relative;
    width: 150px;
  }
  .clip-text {
    color: transparent;
    font-weight: 700;
    font-size: 4rem;
    background-clip: text;
    background-attachment: fixed;
    background-size: 100%;
    -webkit-background-clip: text;
  }  

  .level {
    z-index: 15;
    position: relative;
  }
`);
