
function LoaderImage({ baseUrl }) {

  const imgBase = `${baseUrl}/blocks/`;
  const images = [
    `${imgBase}ice_wallpaper.jpg`,
    `${imgBase}sand_wallpaper.jpg`,
    `${imgBase}water_wallpaper.jpg`,
    `${imgBase}stone_wallpaper.jpg`,
    `${imgBase}rock_wallpaper.jpg`,
    `${imgBase}b07_Dijon.jpg`,
    `${imgBase}b07_Lyon.jpg`,
    `${imgBase}b07_Montpellier.jpg`,
  ]
  images.forEach(img => {
    let image = new Image();
    image.style.position = 'absolute';
    image.style.pointerEvents = 'none';
    image.style.width = '1px';
    image.style.height = '1px';
    image.style.zIndex = -1;
    image.style.left = '-10px';
    image.style.top = '-10px';
    image.src = img;
    document.body.append(image);
  });

  return null;
}

export default LoaderImage;
