import { Box } from '@mui/material';
import styled from 'styled-components';

export const Presentation = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(16)};
  padding-bottom: ${theme.spacing(16)};

  .container {
    position: relative;
  }

  .title {
    position: relative;
    z-index: 15;
    text-align: center;
    line-height: 1.2;
  }

  .box {
    position: absolute;
    width: 30px;
    height: 200px;
    background-color: ${theme.palette.white.main};
    top: 0;
  }
  .right-box {
    right: 0;
  }
  .left-box {
    left: 0;
  }
  .year {
    z-index: 15;
    position: absolute;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }

  .level {
    z-index: 15;
    position: relative;
  }
`);
