import { Box } from '@mui/material';
import styled from 'styled-components';

export const Presentation = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(20)}; 
  padding-bottom: ${theme.spacing(20)}; 

  .container {
    position: relative;
  }

  .title {
    position: relative;
    z-index: 15;
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  }

  .box {
    position: absolute;
    width: 30px;
    height: 200px;
    background-color: ${theme.palette.primary.main};
    top: 0;
  }
  .right-box {
    right: 0;
  }
  .left-box {
    left: 0;
  }
  .year {
    z-index: 15;
    position: absolute;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }
`);
