import { Box, Container, Link, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import StyledButton from '../../components/StyledButton';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Footer } from './style';

function B14(props) {
  const { baseUrl } = props;
  const base = data.blocks.b14footer;
  const pdf = data.pdf;
  const imgBase = `${baseUrl}/blocks`;
  const commonBase = `${baseUrl}/common/`;

  return (
    <Footer className='presentation'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}    >
      <Container className='container'>
        <Grid container spacing={2}>
          <Grid xs={12} md={8}>
            <Box sx={{
              display: "grid",
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' },
              justifyItems: { xs: 'center', sm: 'flex-start' },
              justifyContent: 'space-between',
              columnGap: 8,
              rowGap: 4,
            }}>
              <Stack
                sx={{ width: { xs: '100%', sm: 'auto' }, alignItems: {xs: 'center', sm: 'flex-start'} }}
                direction="column"
                justifyContent="center"
              >
                <Typography className='level' variant='body1' color='secondary'>
                  TÉLÉPHONE
                </Typography>
                <Link href={`tel:${base.tel}`} underline="none">
                  <Typography className='level' variant='body2' color='secondary'>
                    {parse(base.tel)}
                  </Typography>
                </Link>
              </Stack>
               <Stack
                sx={{ width: { xs: '100%', sm: 'auto' }, alignItems: { xs: 'center', sm: 'flex-start' } }}
                direction="column"
                justifyContent="center"
              >
                <Typography className='level' variant='body1' color='secondary'>
                  MAIL
                </Typography>
                <Link href={`mailto:${base.mail}`} underline="none">
                  <Typography className='level' variant='body2' color='secondary'>
                    {parse(base.mail)}
                  </Typography>
                </Link>
              </Stack>
               <Stack
                sx={{ width: { xs: '100%', sm: 'auto' }, alignItems: { xs: 'center', sm: 'flex-start' } }}
                direction="column"
                justifyContent="center"
              >
                <Typography className='level' variant='body1' color='secondary'>
                  ADRESSE
                </Typography>
                <Typography className='level' variant='body2' color='secondary' sx={{textAlign: {xs: 'center', sm: 'left'}}}>
                  {parse(base.address)}
                </Typography>
              </Stack>
               <Stack
                sx={{ width: { xs: '100%', sm: 'auto' }, alignItems: { xs: 'center', sm: 'flex-start' } }}
                direction="column"
                justifyContent="center"
              >
                <Typography className='level' mb={2} variant='body1' color='secondary'>
                  NOUS SUIVRE SUR
                </Typography>
                <Box zIndex="15">
                  <Link href='https://twitter.com/_ERAFP_' target='_blank' underline="none">
                    <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                      <img alt='' src={`${commonBase}${base.twitterLogo}`} />
                      <Typography className='level' variant='body2' color='secondary'>
                        {parse(base.twitter)}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
                <Box zIndex="15" marginTop="5px">
                  <Link href='https://fr.linkedin.com/company/erafp' target='_blank' underline="none">
                    <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                      <img alt='' src={`${commonBase}${base.linkedinLogo}`} />
                      <Typography className='level' variant='body2' color='secondary'>
                        {parse(base.linkedin)}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Stack
              direction="column"
              justifyContent="center"
              spacing={8}
              sx={{ alignItems: { xs: 'center', sm: 'flex-end' }, marginTop: { xs: 4 } }}
            >
              <img alt='' className='image-logo' src={`${commonBase}${base.logo}`} />
              <Box>
                <StyledButton icon='arrowDown' iconMove='down' component="a" href={pdf.rapport} target="_blank" >{parse(base.download)}</StyledButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Box mt={8}>
          <Link href="https://luciole.com/" target='_blank' underline="none">
            <Typography className='level' variant='body2' color='secondary'>
              {parse(base.luciole)}
            </Typography>
          </Link>
        </Box>
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Footer>
  );
}

export default B14;