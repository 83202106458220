import { Box } from '@mui/material';
import styled from 'styled-components';

export const Def = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 400,
  
  '.container' : {
    position: 'relative',
    height: '100%',
  },
  '.title' : {
    position: 'absolute',
    zIndex: 15,
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 600,
    textAlign: 'center', 
    padding: '0 8px 0 8px',
  },
  '.box' : {
    position: 'absolute',
    width: 30,
    height: 200,
    backgroundColor: theme.palette.primary.main,
    bottom: 0, 
  },
  '.right-box' : {
    right: 0,
  },
  '.left-box' : {
    left: 0,
  },
}));