import { Box, Container, Typography } from '@mui/material';
import { data } from '../../utils/data';
import parse from '../../utils/parse';
import StyledDialog from '../../components/StyledDialog';
import StyledButton from '../../components/StyledButton';
import { Value } from './style';
import { useState } from 'react';
import Year from '../../components/Year';

function B09(props) {
  const [active, setActive] = useState([false, false, false, false, false]);

  const handleClick = (position) => {
    const updatedCheckedState = active.map((item, index) =>
      index === position ? !item : item
    );
    setActive(updatedCheckedState);
  };

  const { baseUrl } = props;

  const base = data.blocks.b09value;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Value className='presentation'
      sx={{
        backgroundColor: '#6b8393',
      }}>
      <Container maxWidth='lg' className='container'>
        <Typography variant='h1' color='secondary' className='title' textAlign="center">{parse(base.title)}</Typography>
        <Year />
        <Box sx={{
          display: "grid",
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)' },
          gridAutoRows: '1fr',
          justifyItems: 'center',
          justifyContent: 'space-between',
          columnGap: { xs: 4, md: 8 },
          rowGap: { xs: 12, md: 18 },
        }}>
          {base.images.map((img, idx) => (
            <Box key={idx} height={240}>
              <Box sx={{
                position: 'relative',
              }}>
                <Box
                  component="img"
                  sx={{
                    position: 'relative',
                    margin: 'auto',
                    display: 'block',
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    maxWidth: { xs: 200, },
                    maxHeight: { xs: 360, },
                  }}
                  alt={img.title}
                  src={`${imgBase}/${img.img}`}
                  className='grid-img'
                />
                <Box sx={{ position: 'absolute', top: 'calc(100% - 18px)', right: '20px' }}>
                  <StyledButton icon='plus' size='big' iconMove='plus' bg='blue' onClick={() => handleClick(idx)}></StyledButton>
                </Box>
              </Box>
              <Typography
                variant='body2'
                color='secondary'
                className='grid-desc'
                textAlign="center"
                sx={{
                  position: "relative",
                  zIndex: 15,
                  marginTop: 5,
                }}
              >
                {parse(img.title)}
              </Typography>
              <StyledDialog bgImg={`url(${imgBase}/${base.modal[idx].bg})`} open={active[idx]} handleDialog={() => handleClick(idx)} data={base.modal[idx]} />
            </Box>
          ))}
        </Box>
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Value>
  );
}

export default B09;