import { Box } from '@mui/material';
import styled from 'styled-components';

export const Presentation = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(16)}; 
  padding-bottom: ${theme.spacing(16)}; 

  .container {
    position: relative;
    height: 100%;
  }

  .title {
    position: relative;
    z-index: 15;
    margin: auto;
    padding-bottom: ${theme.spacing(4)}; 
    width: fit-content;
    font-weight: 500;
    line-height: 1.5;
  }

  .box {
    position: absolute;
    width: 30px;
    height: 200px;
    background-color: ${theme.palette.secondary.main};
    top: 0;
  }
  .right-box {
    right: 0;
  }
  .left-box {
    left: 0;
  }
  .year {
    z-index: 15;
    position: absolute;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }
  .kpis {
    position: relative;
    z-index: 15;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(7)};
    justify-content: space-around;
    .kpi {
      text-align: center;
      max-width: 300px;
      line-height: 1.4;
    }
    .clip-text {
      text-transform: capitalize;
      color: transparent;
      font-weight: 700;
      background-clip: text;
      background-attachment: fixed;
      background-size: 100%;
      -webkit-background-clip: text;
    }
  }
`);
