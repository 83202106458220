import { Box } from '@mui/material';
import { ReactComponent as ArrowUp } from '../../res/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../res/arrowDown.svg';
import { ReactComponent as ArrowRight } from '../../res/arrowRight.svg';
import { ReactComponent as ArrowDiagonal } from '../../res/arrowDiagonal.svg';
import { ReactComponent as Plus } from '../../res/add-white.svg';
import { StyledButton } from './style';

function Button(props) {

  const { bgImg = '', icon, iconMove, onClick, bg = '', color = "white", size = "small", ...other } = props;

  return (
    <StyledButton onClick={onClick} className='wholebutton' {...other}>
      {props.children &&
        <Box className='text'>
          {props.children}
        </Box>
      }
      <Box className={`${iconMove} ${color} ${bg} ${size} styledbtn`} >
        {icon === 'arrowUp' &&
          <Box className={`btn ${size}`}>
            <ArrowUp className={`${color} arrow vertical`} style={{ position: 'relative', top: '3px' }}></ArrowUp>
          </Box>
        }
        {icon === 'arrowDown' &&
          <Box className={`btn ${size}`}>
            <ArrowDown className={`${color} arrow down`} style={{ position: 'relative', top: '3px' }}></ArrowDown>
          </Box>
        }
        {icon === 'arrowRight' &&
          <Box className={`btn ${size}`}>
            <ArrowRight className={`${color} arrow horizontal`} style={{ position: 'relative', top: '4px' }}></ArrowRight>
          </Box>
        }
        {icon === 'arrowDiagonal' &&
          <Box className={`btn ${size}`}>
            <ArrowDiagonal className={`${color} arrow diagonal`} style={{ position: 'relative', top: '3px' }}></ArrowDiagonal>
          </Box>
        }
        {icon === 'plus' &&
          <Box className={`btn ${size}`} sx={{ backgroundImage: bgImg, backgroundSize: 'cover', backgroundPosition: 'center', }}>
            <Plus className={`${color} plus-anim`} style={{position: 'relative', bottom: '3px', width: 'inherit', height: "inherit"}}></Plus>
          </Box>
        }
      </Box>
    </StyledButton>
  );
}

export default Button;