import { Box, Container, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Def } from './style';

function B01(props) {

  const base = data.blocks.b01def;

  return (
    <Def className='intro'
      sx={{ bgcolor: "secondary.main" }}
    >
      <Container maxWidth='lg' className='container'>
        <Typography variant='body1' color='primary' className='title'>{parse(base.title)}</Typography>
      </Container>
      <Box sx={{display: {xs: 'none', sm: 'block'}}} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Def>
  );
}

export default B01;