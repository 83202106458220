import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Slide, Box } from '@mui/material';
import Cancel from '@mui/icons-material/CancelOutlined';
import Device from '../../utils/Device';
import parse from '../../utils/parse';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StyledDialog(props) {

  const { open, handleDialog, data, bgImg } = props;

  return (
    <Dialog className='modal-mission' onClose={handleDialog} open={open} maxWidth="md"
      TransitionComponent={Transition}
      BackdropProps={{
        sx: {
          backgroundImage: bgImg,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      }}
      PaperProps={{ sx: { padding: { xs: 2, md: 10 }, bgcolor: "transparent", backdropFilter: 'blur(30px)' } }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={handleDialog}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[50],
            zIndex: 10
          }}
        >
          <Cancel fontSize='large' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {data.title &&
          <Typography variant='h4' color='secondary' textAlign="center">{parse(data.title)}</Typography>
        }
        {data.text &&
          <Typography mt={3} variant={Device.mobile() ? 'body1' : 'h4'} color='secondary' textAlign="center" sx={{ lineHeight: 1.3, fontWeight: 100 }}>{parse(data.text)}</Typography>
        }
        {data.desc &&
          <Typography mt={3} variant='body2' color='secondary' textAlign="center">{parse(data.desc)}</Typography>
        }
        {data.list && (
          <Box>
            {data.type === 'ol' ? (
              <ol start={data.start}>
                {data.list.map((item, idx) => (
                  <li key={idx} style={{ color: 'white' }}>
                    <Typography mt={1} variant='body2' color='secondary'>{parse(item.text)}</Typography>
                  </li>
                ))}
              </ol>
            ) : (
              <ul>
                {data.list.map((item, idx) => (
                  <li key={idx} style={{ color: 'white' }}>
                    <Typography mt={1} variant='body2' color='secondary'>{parse(item.text)}</Typography>
                  </li>
                ))}
              </ul>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default StyledDialog;