import { Box } from '@mui/material';
import styled from 'styled-components';

export const YearStyle = styled(Box)(
  ({ theme }) => `
  .year {
    z-index: 15;
    position: absolute;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }
`);