import { Button } from '@mui/material';
import styled from 'styled-components';

export const StyledButton = styled(Button)(
  ({ theme }) => `
  border-radius: 20px !important;
  &.wholebutton {
    z-index: 2;
    min-width: initial;
  }
  .text{
    max-width: 150px;
    padding-right: 10px;
    text-align: right;
    color: ${theme.palette.white.main};
  }
  .styledbtn {
    text-align: center;
    border: solid 2px ${theme.palette.white.main };
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    &.primary {
      border: solid 2px ${theme.palette.primary.main };
    }
    &.blue  {
      background: ${theme.palette.blue.light };
    }       
  }
  .btn {
    position: relative;
    pointer-events: none;
    padding-top: 3px;
    width: inherit;
    height: inherit;
  }
  .big {
    width: 40px !important;
    height: 40px !important;
  }
  .arrow {
    width: 20px;
    height: 20px;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    &.primary path {
      fill: ${theme.palette.primary.main };
      stroke: ${theme.palette.primary.main };
    }    
  }
  .plus {
    pointer-events: none;
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease-in-out;
    &.primary line {
      stroke: ${theme.palette.primary.main };
    }    
  }
  .arrow:hover {
    transition: transform 0.3s ease-in-out;
  }
  .vertical:hover .arrow, .arrow:hover {
    transition: transform 0.3s ease-in-out;
    animation: moveVertical 1s infinite linear;
  }
  @keyframes moveVertical {
    0 { transform: translateY(0); opacity: 1 }
    48% { transform: translateY(-30px); opacity: 1 }
    49% { transform: translateY(-30px); opacity: 0 }
    50% { transform: translateY(30px); opacity: 0 }
    51% { transform: translateY(30px); opacity: 1 }
    100% { transform: translateY(0); opacity: 1 }
  }
  .down:hover .arrow, .arrow:hover {
    transition: transform 0.3s ease-in-out;
    animation: moveDown 1s infinite linear;
  }
  @keyframes moveDown {
    0 { transform: translateY(0); opacity: 1 }
    48% { transform: translateY(30px); opacity: 1 }
    49% { transform: translateY(30px); opacity: 0 }
    50% { transform: translateY(-30px); opacity: 0 }
    51% { transform: translateY(-30px); opacity: 1 }
    100% { transform: translateY(0); opacity: 1 }
  }
  .horizontal:hover .arrow, .arrow:hover {
    transition: transform 0.3s ease-in-out;
    animation: moveHorizontal 1s infinite linear;
  }
  @keyframes moveHorizontal {
    0 { transform: translateX(0); opacity: 1 }
    48% { transform: translateX(30px); opacity: 1 }
    49% { transform: translateX(30px); opacity: 0 }
    50% { transform: translateX(-30px); opacity: 0 }
    51% { transform: translateX(-30px); opacity: 1 }
    100% { transform: translateX(0); opacity: 1 }
  }
  .diagonal:hover .arrow, .arrow:hover {
    transition: transform 0.3s ease-in-out;
    animation: moveDiagonal 1s infinite linear;
  }
  @keyframes moveDiagonal {
    0 { transform: translate(0, 0); opacity: 1 }
    48% { transform: translate(30px, 30px); opacity: 1 }
    49% { transform: translate(30px, 30px); opacity: 0 }
    50% { transform: translate(-30px, -30px); opacity: 0 }
    51% { transform: translate(-30px, -30px); opacity: 1 }
    100% { transform: translate(0, 0); opacity: 1 }
  }
  &.wholebutton:hover .plus-anim {
    transition: transform 0.3s ease-in-out;
    animation: movePlus 1s infinite linear;
  }
  @keyframes movePlus {
    0 { transform: scale(1) }
    50% { transform: scale(0.5) }
    100% { transform: scale(1) }
  }
`);
