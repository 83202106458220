import { Box } from '@mui/material';
import styled from 'styled-components';

export const Regards = styled(Box)(({ theme }) => ({
    position: 'relative',
    
    '.container' : {
      position: 'relative',
      height: '100%',
    },
    '.title' : {
      position: "relative",
      zIndex: 2,
      textAlign: 'center',
      marginTop: theme.spacing(8), 
      marginBottom: theme.spacing(8), 
    },
    '.box' : {
      position: 'absolute',
      width: 30,
      height: 200,
      bottom: 0, 
    },
    '.right-box' : {
      right: 0,
    },
    '.left-box' : {
      left: 0,
    },
    '.mybtn' : {
      marginTop: 20,
    },

    '.level': {
      zIndex: 15,
    }
  }
));