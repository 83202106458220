import { Box } from '@mui/material';
import styled from 'styled-components';

export const ISR = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(16)}; 
  padding-bottom: ${theme.spacing(16)}; 

  .container {
    position: relative;
    height: 100%;
  }

  .title {
    position: relative;
    z-index: 15;
    margin: auto;
    padding-bottom: ${theme.spacing(4)}; 
    width: fit-content;
    font-weight: 500;
    line-height: 1.5;
  }
  .year {
    position: absolute;
    z-index: 15;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }
`);
