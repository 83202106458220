import { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { data } from '../../utils/data';
import parse from '../../utils/parse';
import Device from '../../utils/Device';

import { Keydate } from './style';
import Year from '../../components/Year';

function B05(props) {
  const [active, setActive] = useState([false, false, false, false, false]);

  const handleClick = (position) => {
    const updatedCheckedState = active.map((item, index) =>
      index === position ? !item : false
    );
    setActive(updatedCheckedState);
  };

  const handleClickAway = () => {
    setActive([false, false, false, false, false]);
  };

  const { baseUrl } = props;

  const base = data.blocks.b05keydate;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Keydate className='intro'
      sx={{ bgcolor: "secondary.main" }}
    >
      <Container maxWidth='lg' className='container' sx={{ paddingX: { xs: 2, sm: 6, md: 2 } }}>
        <Typography variant='h1' color='primary' className='title'>{parse(base.title)}</Typography>
        <Year color="primary" />
        <ClickAwayListener onClickAway={Device.mobile() ? handleClickAway : () => {}}>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)' },
            columnGap: 2,
            rowGap: 2,
          }}>
            {base.keydates.map((key, idx) => (
              <Box key={idx} sx={{
                position: 'relative'
              }}>
                <Box className='ghost'
                  sx={{
                    position: 'absolute',
                    zIndex: 5,
                    inset: 0,
                    margin: 'auto',
                    display: 'block',
                    objectFit: 'cover',
                    width: '100%',
                    height: 'auto',
                    maxHeight: { xs: 360, },
                  }}
                  onClick={Device.mobile() ? () => handleClick(idx) : null}
                ></Box>
                <Box className={`wrapper item-${idx} ${Device.mobile() ? (active[idx] ? 'openTile' : '') : ''}`}>
                  <Typography variant='h5' color='primary' textAlign="center" pb={2} className='date'>{parse(key.date)}</Typography>
                  <Box
                    component="img"
                    sx={{
                      margin: 'auto',
                      display: 'block',
                      objectFit: 'cover',
                      width: '100%',
                      height: 'auto',
                      maxHeight: { xs: 360, },
                    }}
                    alt={key.bg}
                    src={`${imgBase}/${key.bg}`}
                    className='grid-img'
                  />
                </Box>
                <Typography
                  variant='body2'
                  color='black'
                  className='grid-desc'>{parse(key.desc)}</Typography>
              </Box>
            ))}
          </Box>
        </ClickAwayListener>
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Keydate>
  );
}

export default B05;