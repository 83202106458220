import { Box, Container, Stack, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Presentation } from './style';
import Year from '../../components/Year';

function B12(props) {
  const { baseUrl } = props;

  const base = data.blocks.b12steps;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Presentation className='presentation'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}    >
      <Container maxWidth='lg' className='container'>
        <Typography variant='h1' color='secondary' className='title'>{parse(base.title)}</Typography>
        <Year/>
     
        <Stack justifyContent='space-between' sx={{ marginTop: 8, flexDirection: {xs: 'column', sm: 'row'} }}>
          {base.texts.map((text, idx) => (
            <Typography key={idx} variant='body2' color='secondary' className='text level'
              sx={{ borderTop: 'solid 6px #FFF', paddingTop: 2, maxWidth: {xs: 'auto', sm: '30%'}, marginTop: {xs: 2, sm: 0} }}
            >
              {parse(text)}
            </Typography>
          ))}
        </Stack>
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Presentation>
  );
}

export default B12;