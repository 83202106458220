import { Box, Container, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Presentation } from './style';
import Year from '../../components/Year';

function B04(props) {
  const { baseUrl } = props;

  const base = data.blocks.b04kpis;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Presentation className='presentation'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}    >
      <Container maxWidth='lg' className='container'>
        <Typography variant='h1' color='secondary' className='title' textAlign="center">{parse(base.title)}</Typography>
        <Year />    
        <Box className='kpis'
          >
          {base.kpis.map((kpi, idx) => (
            <Box key={idx} className='kpi'>
              <Typography variant="h1" className='clip-text' sx={{
                backgroundImage: `url(${imgBase}/${base.bgText})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>{parse(kpi.number)}</Typography>
              <Typography variant="body2" color="secondary">{parse(kpi.desc)}</Typography>
            </Box>
          ))}
        </Box>      
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Presentation>
  );
}

export default B04;