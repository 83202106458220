import { Box } from '@mui/material';
import styled from 'styled-components';

export const Menu = styled(Box)(
  ({ theme }) => `

  .menu-container {
    position: fixed;
    z-index: 101;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    background-color: #BCBCBC;
    transition: right 0.3s ease-in-out;

    &.open {
      right: 0;
    }
  }

  .hamburger {
    position: fixed;
    top: 35px;
    right: 35px;
    width: 40px;
    height: 30px;
    z-index: 102;
    cursor: pointer;
    mix-blend-mode: difference;
    &:hover .bar4 {
      width: 40px !important;
    }

    &.open {
      .bar1{
        transform: translateX(40px);
        background-color: transparent;
      }

      .bar2{
        transform: rotate(45deg);
      }

      .bar3{
        transform: rotate(-45deg);
      }

      .bar4{
        transform: translateX(-40px);
        background-color: transparent;
      }
    }
  }

  .hamburger .bar {
    padding: 0;
    width: 40px;
    height: 4px;
    background-color: ${theme.palette.secondary.main};
    display: block;
    border-radius: 2px;
    transition: all 0.4s ease-in-out;
    position: absolute; 
  }

  .bar1 {
    top: 0;
  }

  .bar2,
  .bar3 {
    top: 13.5px;
  }

  .bar3 {
    right: 0;
  }

  .bar4 {
    width: 20px !important;
    bottom: 0;
  }

  .checkbox2:checked + label > .hamburger2 > .bar1{
    transform: translateX(40px);
    background-color: transparent;
  }

  .checkbox2:checked + label > .hamburger2 > .bar2{
    transform: rotate(45deg);
  }

  .checkbox2:checked + label > .hamburger2 > .bar3{
    transform: rotate(-45deg);
  }

  .checkbox2:checked + label > .hamburger2 > .bar4{
    transform: translateX(-40px);
    background-color: transparent;
  }
`);
