import { Box } from '@mui/material';
import styled from 'styled-components';

export const Card = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding-top: ${theme.spacing(12)}; 
  padding-bottom: ${theme.spacing(12)}; 

  .container {
    position: relative;
    height: 100%;
  }

  .title {
    position: relative;
    z-index: 15;
    margin: auto;
    padding-bottom: ${theme.spacing(4)}; 
    width: fit-content;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
  }

  .show-more {
    width: 54px;
    height: 54px;
    position: absolute;
    bottom:-30px;
    right:20px;
    background-color: #6b8393;
    border-radius: 50%;
    cursor: pointer;
  }

   .year {
    position: absolute;
    z-index: 15;
    width: fit-content;
    top: 50%;
  }
  .right-year {
    transform: translateY(-50%) rotate(90deg);
    left: 100%;
  }
  .left-year {
    transform: translateY(-50%) rotate(-90deg);
    right: 100%;
  }

  .box {
    position: absolute;
    width: 30px;
    height: 200px;
    background-color: ${theme.palette.primary.main};
    bottom: 0;
  }
  .right-box {
    right: 0;
  }
  .left-box {
    left: 0;
  }

  .card {
    z-index: 15;
    position: relative;
    width: 25%;
    min-width: 300px;
    position: relative;
    min-height: 25em;
    height: auto;
    perspective: 150em;
    cursor: pointer;
  
    &__side {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 25em;
      backface-visibility: hidden;
      transition: all .6s ease;
      box-shadow: 1em 1em 2em rgba(0,0,0,.2);
      
      &--front {
        z-index: 16;
        &-1 {
          background-color:  #13769D; 
        }
        &-2 {
         background-color:  ${theme.palette.primary.main}; 
          
        }
        &-3 {
          background-color:  #A6BCD4; 
        }
      }
      
      &--back {
        color: ${theme.palette.secondary.main};
        transform: rotateY(180deg);
        display: flex;
        align-items: center;
        background-size: cover;
        background-position: center;
        &-1 {
          //background-image: linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark});        
        }
        
        &-2 {
          //background-image: linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark});
        }
        
        &-3 {
          //background-image: linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark});
        }
      }
    }
  
    .desktop &:hover .card__side--front, &.openFront .card__side--front {
      transform: rotateY(-180deg) scale(1.1);
    }
    
    .desktop &:hover .card__side--back, &.openBack .card__side--back {
      transform: rotate(0) scale(1.1);
    }

    &__title {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-transform: uppercase;
      text-align: center;
      padding: 1em 0;
      gap: 8px;
    }

    &__description {
      position: relative;
      padding: ${theme.spacing(2)};
      height: 80%;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(30px);
      margin: auto;
      z-index: 15;
    }

    .picto-card {
      height: 150px;
      width: auto;
    }
  }

`);
