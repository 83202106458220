import { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Device from '../../utils/Device';
import { data } from '../../utils/data';
import parse from '../../utils/parse';
import StyledButton from '../../components/StyledButton';
import { Card } from './style';
import Year from '../../components/Year';
import { ReactComponent as Nuke } from '../../res/Nuke.svg';
import { ReactComponent as House } from '../../res/House.svg';
import { ReactComponent as Hands } from '../../res/Hands.svg';


function B10(props) {
  const { baseUrl } = props;

  const base = data.blocks.b10card;
  const imgBase = `${baseUrl}/blocks/`;

  const [active, setActive] = useState([false, false, false]);

  const handleClick = (position) => {
    const updatedCheckedState = active.map((item, index) =>
      index === position ? !item : false
    );
    setActive(updatedCheckedState);
  };

  const handleClickAway = () => {
    setActive([false, false, false]);
  };

  const bg2 = `url(${imgBase}sand_wallpaper.jpg)`;
  const bg1 = `url(${imgBase}water_wallpaper.jpg)`;
  const bg3 = `url(${imgBase}ice_wallpaper.jpg)`;

  return (
    <Card className='intro'
      sx={{ bgcolor: "secondary.main" }}
    >
      <Container maxWidth='lg' className='container' sx={{ paddingX: { xs: 2, sm: 6, md: 2 } }}>
        <Typography variant='h5' color='primary' className='title'>{parse(base.title)}</Typography>
        <Year color="primary" />
        <ClickAwayListener onClickAway={Device.mobile() ? handleClickAway : () => { }}>
          <Box sx={{
            display: "flex",
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            alignContent: 'center',
            alignItems: 'center',
            gap: { xs: 6, sm: 3 },
          }}>
            <Box className={`card ${Device.mobile() ? (active[0] ? 'openFront openBack' : '') : ''}`} onClick={Device.mobile() ? () => handleClick(0) : null}>
              <div className="card__side card__side--front card__side--front-1">
                <div className="card__title">
                  <Nuke className="picto-card"></Nuke>
                  <Typography variant='body1' color='secondary'>{parse(base.defi[0].title)}</Typography>
                </div>
                <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                  <StyledButton icon='arrowDiagonal' iconMove='vertical' ></StyledButton>
                </Box>
              </div>
              <div className="card__side card__side--back card__side--back-1" style={{ backgroundImage: bg1 }}>
                <div className="card__description">
                  <Typography variant='body2' color='secondary'>{parse(base.defi[0].text)}</Typography>
                </div>
              </div>
            </Box>
            <Box className={`card ${Device.mobile() ? (active[1] ? 'openFront openBack' : '') : ''}`} onClick={Device.mobile() ? () => handleClick(1) : null}>
              <div className="card__side card__side--front card__side--front-2">
                <div className="card__title">
                  <House className="picto-card"></House>

                  <Typography variant='body1' color='secondary'>{parse(base.defi[1].title)}</Typography>
                </div>
                <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                  <StyledButton icon='arrowDiagonal' iconMove='vertical' ></StyledButton>
                </Box>
              </div>
              <div className="card__side card__side--back card__side--back-2" style={{ backgroundImage: bg2 }}>
                <div className="card__description">
                  <Typography variant='body2' color='secondary'>{parse(base.defi[1].text)}</Typography>
                </div>
              </div>
            </Box>
            <Box className={`card ${Device.mobile() ? (active[2] ? 'openFront openBack' : '') : ''}`} onClick={Device.mobile() ? () => handleClick(2) : null}>
              <div className="card__side card__side--front card__side--front-3">
                <div className="card__title">
                  <Hands className="picto-card"></Hands>

                  <Typography variant='body1' color='secondary'>{parse(base.defi[2].title)}</Typography>
                </div>
                <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                  <StyledButton icon='arrowDiagonal' iconMove='vertical' ></StyledButton>
                </Box>
              </div>
              <div className="card__side card__side--back card__side--back-3" style={{ backgroundImage: bg3 }}>
                <div className="card__description">
                  <Typography variant='body2' color='secondary'>{parse(base.defi[2].text)}</Typography>
                </div>
              </div>
            </Box>
          </Box>
        </ClickAwayListener>
      </Container>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Card>
  );
}

export default B10;