import { Box, Container, Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Presentation } from './style';
import Year from '../../components/Year';

function B02(props) {
  const { baseUrl } = props;

  const base = data.blocks.b02presentation;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Presentation className='presentation'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}    >
      <Container maxWidth='lg' className='container'>
        <Typography variant='body1' color='secondary' className='title'>{parse(base.title)}</Typography>
        <Year />    
      </Container>
      <Box sx={{display: {xs: 'none', sm: 'block'}}} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Presentation>
  );
}

export default B02;