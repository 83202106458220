import { Box, Container, Typography } from '@mui/material';
import StyledButton from '../../components/StyledButton';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { Climat } from './style';
import { useState } from 'react';
import StyledDialog from '../../components/StyledDialog';
import Year from '../../components/Year';
import { ReactComponent as Usine } from '../../res/usine.svg';
import { ReactComponent as Light } from '../../res/light.svg';
import { ReactComponent as Hand } from '../../res/hand.svg';
import { ReactComponent as Thermo } from '../../res/thermo.svg';

const iconList = {
  0: Usine,
  1: Light,
  2: Hand,
  3: Thermo,
};

function B11(props) {
  const [active, setActive] = useState([false, false, false, false]);

  const handleClick = (position) => {
    const updatedCheckedState = active.map((item, index) =>
      index === position ? !item : item
    );
    setActive(updatedCheckedState);
  };

  const { baseUrl } = props;

  const base = data.blocks.b11climat;
  const imgBase = `${baseUrl}/blocks`;

  return (
    <Climat className='presentation'
      sx={{
        backgroundImage: `url(${imgBase}/${base.bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}    >
      <Container maxWidth='lg' className='container'>
        <Typography variant='h1' color='secondary' className='title' textAlign='center'>{parse(base.title)}</Typography>
        <Typography className='level' variant='body2' color='secondary' textAlign="center"
          sx={{
            maxWidth: '800px',
            marginTop: 3,
            marginX: 'auto',
          }}
        >
          {parse(base.subtitle)}
        </Typography>
        <Box sx={{ marginTop: 7 }}>
          <Typography className='level' variant='h4' color='primary' fontWeight={400} textAlign="center">{parse(base.text1)}</Typography>
        </Box>
        <Year />
      </Container>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: {xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)'},
        gridAutoRows: '1fr',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        gap: 2,
        backgroundColor: '#6b8393',
        width: '100%',
        minHeight: '170px',
        marginTop: 9,
        paddingX: {xs: 1, sm: 2, md: 8},
        paddingTop: { xs: 2, lg: 0}
      }}>
        {base.bandeau.map((item, idx) => {
          const Logo = iconList[idx];
          return (
            <Box key={idx} sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent:{xs: 'flex-start', lg: 'center'},
            }}>
              <Logo className="picto-climat"></Logo>
              <Typography className='level' margin="revert" textAlign="center" variant='h4' color='secondary'>{parse(item.text)}</Typography>
              <Box sx={{
                position: 'initial',
                top: { xs: 'calc(100% - 50px)', sm:'calc(100% - 38px)', md: 'calc(100% - 18px)', lg: 'calc(100% - 24px)' },
                right: { xs: '50%', md: '20px' },
                transform: { xs: 'none', md: 'none' }
              }}>
                <StyledButton bgImg={`url(${imgBase}/${base.bg})`} icon='plus' size="big" iconMove='plus' bg='blue' onClick={() => handleClick(idx)}></StyledButton>
              </Box>
              <StyledDialog bgImg={`url(${imgBase}/${base.bg})`} open={active[idx]} handleDialog={() => handleClick(idx)} data={base.modal[idx]} />
            </Box>
          )
        })}
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box right-box'></Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} className='box left-box'></Box>
    </Climat>
  );
}

export default B11;